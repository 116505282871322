import { Component, HostListener, OnInit } from '@angular/core';

declare var Calendly: any;

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  // @HostListener('window:scroll', ['$event'])

  constructor() {}

  ngOnInit(): void {}

  @HostListener('window:scroll', ['$event'])
  showBottomBar() {
    // return window.pageYOffset !== 0;
    return true;
  }

  openCal(): boolean {
    Calendly.initPopupWidget({
      url: 'https://calendly.com/kevin-1176/15min',
    });
    return false;
  }

  goToTop($element: any): void {
    $element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  }

  mobile() {
    return window.innerWidth < 1280;
  }
}
