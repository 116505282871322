import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';

const USER_LOOKUP_BY_EMAIL = gql`
  query BootCampUserLookup($email: String) {
    users(where: { email: { _eq: $email } }) {
      id
      first_name
      email
      last_name
      annuity_premium
      phone
      current_imos
      street
      city
      state
      zip
    }
  }
`;

const SEND_EMAIL = gql`
  mutation SendEmail(
    $html: String!
    $recipient_email: String!
    $recipient_name: String!
    $subject: String!
  ) {
    action_send_email(
      html: $html
      recipient_email: $recipient_email
      recipient_name: $recipient_name
      subject: $subject
    ) {
      ok
    }
  }
`;

const REGISTER_TO_BOOTCAMP_EVENT = gql`
  mutation InsertBCAttendee($bce_id: Int, $user_id: Int) {
    insert_boot_camp_attendees_one(
      object: { bce_id: $bce_id, user_id: $user_id }
    ) {
      id
    }
  }
`;

const GET_BOOTCAMP_EVENTS = gql`
  query GetBCEvents($now: timestamp) {
    boot_camp_events(
      where: { event_date: { _gt: $now } }
      order_by: { event_date: asc }
    ) {
      capacity
      event_date
      id
      boot_camp_attendees_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;

const REGISTER_NEW_USER = gql`
  mutation BootCampRegistration(
    $annuity_premium: String!
    $business_name: String!
    $city: String!
    $current_imos: String!
    $email: String!
    $first_name: String!
    $password: String!
    $last_name: String!
    $phone: String!
    $state: String!
    $street: String!
    $zip: String!
  ) {
    action_registration(
      annuity_premium: $annuity_premium
      business_name: $business_name
      city: $city
      current_imos: $current_imos
      email: $email
      first_name: $first_name
      phone: $phone
      state: $state
      street: $street
      zip: $zip
      last_name: $last_name
      password: $password
    ) {
      ok
      id
      reason
    }
  }
`;

export interface CreateNewUserInput {
  annuity_premium: string;
  business_name: string;
  city: string;
  current_imos: string;
  email: string;
  first_name: string;
  password: string;
  last_name: string;
  phone: string;
  state: string;
  street: string;
  zip: string;
}

@Injectable({
  providedIn: 'root',
})
export class GraphqlMutationService {
  constructor(private apollo: Apollo) {}

  getEventDates() {
    return this.apollo.query({
      query: GET_BOOTCAMP_EVENTS,
      variables: {
        now: new Date(),
      },
    });
  }

  sendEmail(user: any, event_date: String, new_registration: Boolean) {
    console.log('SENDING EMAIL V:1');
    if (new_registration) {
      let html = `
      Marketing: Please follow up with the newly registered agent below for confirmation and additional licensing/marketing support opportunities. 
      <span style='color: red'>This agent was not in our system prior to registering for the Bootcamp.</span><br>
      <br>
      New Agent Registered:<br>
      ${user.first_name} ${user.last_name}<br>
      <br>
      Address: ${user.street} ${user.city}, ${user.state} ${user.zip}<br>
      Phone: ${user.phone}<br>
      Email: ${user.email}<br>
      Current IMOs: ${user.current_imos}<br>
      Annuity Premium: ${user.annuity_premium}<br>
      `;
      this.apollo
        .mutate({
          mutation: SEND_EMAIL,
          variables: {
            html,
            recipient_email: 'steve@annuitycheck.com',
            recipient_name: 'Steve Hutchinson',
            subject:
              'MI6, do you copy? The raven has taken flight. A NEW agent has registered for the Bootcamp',
          },
        })
        .subscribe(() => {});
      this.apollo
        .mutate({
          mutation: SEND_EMAIL,
          variables: {
            html,
            recipient_email: 'kevin@ecamarketing.com',
            recipient_name: 'Kevin Nordberg',
            subject:
              'MI6, do you copy? The raven has taken flight. A NEW agent has registered for the Bootcamp',
          },
        })
        .subscribe(() => {});
    } else {
      let html = `
      Marketing: Please check the status of the below agent and follow up for additional licensing/marketing support opportunities. 
      <span style='color: red'>This agent was in the system prior to registering for the Bootcamp.</span><br>
      <br>
      New Agent Registered:<br>
      ${user.first_name} ${user.last_name}<br>
      <br>
      Address: ${user.street} ${user.city}, ${user.state} ${user.zip}<br>
      Phone: ${user.phone}<br>
      Email: ${user.email}<br>
      Current IMOs: ${user.current_imos}<br>
      Annuity Premium: ${user.annuity_premium}<br>
      `;
      this.apollo
        .mutate({
          mutation: SEND_EMAIL,
          variables: {
            html,
            recipient_email: 'steve@annuitycheck.com',
            recipient_name: 'Steve Hutchinson',
            subject:
              'MI6, do you copy? The raven has taken flight. A CURRENT agent has registered for the Bootcamp',
          },
        })
        .subscribe(() => {});
      this.apollo
        .mutate({
          mutation: SEND_EMAIL,
          variables: {
            html,
            recipient_email: 'kevin@ecamarketing.com',
            recipient_name: 'Kevin Nordberg',
            subject:
              'MI6, do you copy? The raven has taken flight. A CURRENT agent has registered for the Bootcamp',
          },
        })
        .subscribe(() => {});
    }
    this.apollo
      .mutate({
        mutation: SEND_EMAIL,
        variables: {
          html: `Hi ${user.first_name} ${user.last_name},<br><br>
        Thank you for registering for our upcoming AnnuityCheck Bootcamp.
        The Bootcamp will be from 11am until 4pm on Friday at Fleming’s Prime Steakhouse and Wine Bar.
        Fleming’s which is directly connected to:<br><br>
        The Hilton Scottsdale Resort & Villas<br>
        6333 N. Scottsdale Road<br>
        Scottsdale, AZ 85250<br><br>
        Below are your event details:<br><br>
        ${event_date} at 11am<br>
        Fleming’s Prime Steakhouse<br>
        6333 N. Scottsdale Road<br>
        Scottsdale, AZ 85250<br><br>
        We designed the program so that you can fly in and out the same day if preferred.<br><br>
        If you are arriving on Thursday, we have a limited number of rooms available at a discounted rate.
        Hotel Reservations: 877-214-6722<br><br>
        Lucas Rynda will be reaching out to confirm your registration for final dinner count and to answer any other questions.
        Please contact Lucas with any changes or if you need to cancel your registration: lucas@ecamarketing.com<br><br>
        We look forward to seeing you soon!<br><br>
        Steve Hutchinson,<br><br>
        Co-Founder AnnuityCheck
      `,
          recipient_email: user.email,
          recipient_name: `${user.first_name}, ${user.last_name}`,
          subject: 'Your Bootcamp Registration!',
        },
      })
      .subscribe(() => {});
  }

  getUserByEmail(email: string) {
    return this.apollo.query({
      query: USER_LOOKUP_BY_EMAIL,
      variables: { email },
    });
  }

  insertBCEAttendee(bce_id: number, user_id: number) {
    return this.apollo.mutate({
      mutation: REGISTER_TO_BOOTCAMP_EVENT,
      variables: {
        bce_id,
        user_id,
      },
    });
  }

  createNewRegistrant(payload: CreateNewUserInput) {
    return this.apollo.mutate({
      mutation: REGISTER_NEW_USER,
      variables: payload,
    });
  }
}
