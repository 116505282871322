<div id="pageTop" #top></div>
<mat-toolbar class="toolbar" fxLayout="row" fxLayoutAlign="space-between">
  <img src="/assets/images/logo.png" class="logo" />

  <div class="bootcampHotline">
    <!-- <a class="phoneNumber" href="tel:+1-833-333-2021">
      <span *ngIf="!mobile()">Bootcamp Hotline: </span>1 (833) 333-2021</a
    > -->
    <button mat-button class="phoneNumber" (click)="openCal()">
      Schedule a Call
    </button>
  </div>

  <!-- <div *ngIf="!mobile()" class="bootcampHotline">
    <h2><span *ngIf="!mobile()">Bootcamp Hotline: </span>1 (833)333-2021</h2>
  </div> -->
</mat-toolbar>

<mat-toolbar
  *ngIf="showBottomBar()"
  class="bottom-toolbar"
  fxLayout="row"
  fxLayoutAlign="space-between"
>
  <span fxFlex></span>

  <button mat-button class="registerButton" (click)="goToTop(top)">
    Register Now
  </button>

  <span fxFlex></span>
</mat-toolbar>
