<app-navbar></app-navbar>

<div class="cardContainer" *ngIf="!mobile()">
  <div class="firstSection" fxLayout="row">
    <div class="wrapper" fxLayout.lt-lg="column" fxLayout.gt-md="row">
      <div class="leftSide1">
        <div class="main-page-text">
          <h1>Get in Front of 30</h1>
          <h1>Annuity Owners</h1>
          <h1>EVERY Month!</h1>
          <br />
          <p>Come to Scottsdale and learn the #1 Software/Seminar Process.</p>
          <p>Agents are averaging $500,000 of production in</p>
          <p>the 1st week following our Bootcamp!</p>
        </div>
        <div class="top-page-images">
          <img class="top-image" src="/assets/images/Group 76.svg" />
        </div>
      </div>

      <div class="rightSide1">
        <img id="cloud1" src="/assets/images/Path 13.svg" />
        <img id="cloud2" src="/assets/images/Path 12.svg" />

        <h2 *ngIf="formStep === 1" class="bootcampTitle">
          2022 Bootcamp Registration
        </h2>
        <h2 *ngIf="formStep !== 1" style="visibility: hidden">.</h2>
        <mat-card class="registerForm">
          <div *ngIf="formStep === 1">
            <form [formGroup]="completeRegisterForm">
              <!-- <mat-card-subtitle>Date Attending</mat-card-subtitle> -->
              <mat-form-field appearance="outline">
                <mat-select
                  formControlName="event"
                  placeholder="Select Event Date"
                >
                  <mat-option
                    *ngFor="let value of eventDates; let i = index"
                    [value]="value.date"
                    (click)="setEventId(i)"
                    >{{ value.date }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </form>

            <form [formGroup]="registerForm">
              <mat-form-field appearance="outline">
                <input
                  matInput
                  type="text"
                  formControlName="email"
                  placeholder="Email"
                />
              </mat-form-field>

              <button
                mat-button
                [disabled]="!registerFormValid()"
                class="registerButton"
                (click)="onRegisterButton()"
              >
                Register
              </button>
            </form>
          </div>

          <div *ngIf="formStep == 2">
            <form class="user-details-form" [formGroup]="userDetailsForm">
              <mat-card-subtitle>First Name</mat-card-subtitle>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  type="text"
                  formControlName="firstName"
                  placeholder="First Name"
                />
              </mat-form-field>

              <mat-card-subtitle>Last Name</mat-card-subtitle>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  type="text"
                  formControlName="lastName"
                  placeholder="Last Name"
                />
              </mat-form-field>

              <mat-card-subtitle>Phone</mat-card-subtitle>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  type="tel"
                  mask="(000) 000-0000"
                  formControlName="phone"
                  placeholder="Phone"
                />
              </mat-form-field>

              <mat-card-subtitle>Address</mat-card-subtitle>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  type="text"
                  formControlName="street"
                  placeholder="Address"
                />
              </mat-form-field>

              <mat-card-subtitle>City</mat-card-subtitle>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  type="text"
                  formControlName="city"
                  placeholder="City"
                />
              </mat-form-field>

              <mat-card-subtitle>State</mat-card-subtitle>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  type="text"
                  formControlName="state"
                  placeholder="State"
                />
              </mat-form-field>

              <mat-card-subtitle>Zip</mat-card-subtitle>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  type="text"
                  formControlName="zip"
                  placeholder="Zip"
                />
              </mat-form-field>

              <mat-card-subtitle>Password</mat-card-subtitle>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  type="password"
                  formControlName="password"
                  placeholder="Password"
                />
              </mat-form-field>

              <button
                mat-button
                [disabled]="!userDetailsForm.valid"
                class="registerButton"
                (click)="onNext()"
              >
                Next
              </button>
            </form>
          </div>

          <div *ngIf="formStep === 3">
            <form [formGroup]="completeRegisterForm">
              <!-- <mat-card-subtitle>Are you currently licensed with Annuity Check</mat-card-subtitle>
              <div fxLayout="row">
                <button (click)="changeLicensedValue(true)"
                  [ngClass]="completeRegisterForm.get('licensed')?.value === true ? 'activeDecisionButton' : ''"
                  class="leftButton decisionButton" mat-button>Yes</button>
                <button (click)="changeLicensedValue(false)"
                  [ngClass]="completeRegisterForm.get('licensed')?.value === false ? 'activeDecisionButton' : ''"
                  class="rightButton decisionButton" mat-button>No</button>
              </div> -->

              <!-- <mat-card-subtitle>Do you currently host seminars?</mat-card-subtitle>
              <div fxLayout="row">
                <button (click)="changeHostingValue(true)"
                  [ngClass]="completeRegisterForm.get('hosting')?.value === true ? 'activeDecisionButton' : ''"
                  class="leftButton decisionButton" mat-button>Yes</button>
                <button (click)="changeHostingValue(false)"
                  [ngClass]="completeRegisterForm.get('hosting')?.value === false ? 'activeDecisionButton' : ''"
                  class="rightButton decisionButton" mat-button>No</button>
              </div> -->

              <!-- Current Imos -->
              <mat-card-subtitle>Current Affiliations</mat-card-subtitle>
              <mat-form-field floatLabel="never" appearance="outline">
                <input
                  matInput
                  placeholder="Current IMOs and BD affiliations comma separated"
                  type="text"
                  formControlName="currentImos"
                  required
                />
              </mat-form-field>

              <mat-card-subtitle
                >Annuity Premium Written: Last 12 Months</mat-card-subtitle
              >
              <mat-form-field appearance="outline">
                <mat-select
                  formControlName="premium"
                  placeholder="Select Varifiable Premium Range"
                >
                  <mat-option
                    *ngFor="let value of annuityPremiumOptions"
                    [value]="value"
                    >{{ value }}</mat-option
                  >
                </mat-select>
              </mat-form-field>

              <mat-card-subtitle>Comments</mat-card-subtitle>
              <mat-form-field appearance="outline">
                <textarea
                  matInput
                  formControlName="comments"
                  placeholder="Comments..."
                ></textarea>
              </mat-form-field>

              <button
                mat-button
                [disabled]="!completeRegisterForm.valid"
                class="registerButton"
                (click)="onCompleteRegistration()"
              >
                Complete Registration
              </button>
            </form>
          </div>

          <div *ngIf="formStep === 4">
            <img id="airplane" src="/assets/images/airplane.svg" />
            <div>
              <span fxFlex></span>

              <div class="dateSquare">
                <p class="small">{{ dayName }}</p>
                <p class="big">{{ dayNumber }}</p>
                <p class="small">{{ monthName }}</p>
              </div>
            </div>

            <h2 class="purpleH2">You are now registered!</h2>

            <p>Someone will be giving you a</p>
            <p>call shortly to confirm your travel plans.</p>

            <img src="/assets/images/arrow.png" class="finalImgStep" />

            <p>You will receive a confirmation email</p>
            <p>at {{ registerForm.get("email")?.value }}</p>
          </div>
        </mat-card>
      </div>
    </div>
  </div>

  <div class="bodySection" fxLayout="row">
    <div class="side">
      <img src="/assets/images/four-trillion.svg" />
    </div>

    <div class="side">
      <span fxFlex></span>

      <div class="text">
        <div>
          <h1>4-Trillion</h1>
          <h1>Dollar Market</h1>
          <div class="separator"></div>
          <h3>Over 2 trillion in net Variable Annuity</h3>
          <h3>Assets alone and growing daily.</h3>
        </div>
      </div>

      <span fxFlex></span>
    </div>
  </div>

  <div class="bodySection" fxLayout="row">
    <div class="side">
      <span fxFlex></span>

      <div class="text">
        <div>
          <h1>Qualify for</h1>
          <h1>44 Cent Mail with</h1>
          <h1>no minimums</h1>
          <div class="separator"></div>
          <h3>Industry low for our proven full-color</h3>
          <h3>trifold mailer</h3>
          <div class="separator"></div>
          <p>We've generated 100's of millions of premium from</p>
          <p>current annuity owners at a cost our competitors</p>
          <p>can't match.... PLUS</p>
        </div>
      </div>

      <span fxFlex></span>
    </div>

    <div class="side">
      <img src="/assets/images/ud_message_sent.svg" />
    </div>
  </div>

  <br />
  <br />

  <div class="bodySection" fxLayout="row">
    <div class="side">
      <img src="/assets/images/ud_delivery.svg" />
    </div>

    <div class="side">
      <span fxFlex></span>

      <div class="text">
        <div>
          <h1>Protected</h1>
          <h1>Campaign</h1>
          <h1>Territories</h1>
          <div class="separator"></div>
          <h3>You choose your Zip Codes</h3>
          <h3>(go big or go small, you decide)</h3>
        </div>
      </div>

      <span fxFlex></span>
    </div>
  </div>

  <div class="bodySection" fxLayout="row">
    <div class="side">
      <span fxFlex></span>

      <div class="text">
        <div>
          <h1>Top Carriers</h1>
          <div class="separator"></div>
          <h3>Access to the very best annuity products</h3>
        </div>
      </div>

      <span fxFlex></span>
    </div>

    <div class="side">
      <img src="/assets/images/top-carriers.svg" />
    </div>
  </div>

  <div class="bodySection" fxLayout="row">
    <div class="side">
      <img src="/assets/images/ud_all_data.svg" />
    </div>

    <div class="side">
      <span fxFlex></span>

      <div class="text">
        <div>
          <h1>Lucrative Marketing</h1>
          <h1>Reimbursement Plan</h1>
          <div class="separator"></div>
          <h3>We directly invest back into your ability to scale</h3>
          <h3>more rapidly</h3>
        </div>
      </div>

      <span fxFlex></span>
    </div>
  </div>

  <div class="bodySection" fxLayout="row">
    <div class="side">
      <span fxFlex></span>

      <div class="text">
        <div>
          <h1>FREE</h1>
          <h1>Proven Powerpoint</h1>
          <h1>Presentation</h1>
          <div class="separator"></div>
          <h3>Stay on the cutting edge at all</h3>
          <h3>times with tools and page by page transcript</h3>
        </div>
      </div>

      <span fxFlex></span>
    </div>

    <div class="side">
      <img src="/assets/images/ud_pie_graph.svg" />
    </div>
  </div>

  <div class="bodySection" fxLayout="row">
    <div class="side">
      <img src="/assets/images/ud_predictive_analytics.svg" />
    </div>

    <div class="side">
      <span fxFlex></span>

      <div class="text">
        <div>
          <h1>FREE</h1>
          <h1>AnnuityCheck</h1>
          <h1>Snapshot Tool</h1>
          <div class="separator"></div>
          <h3>Calculate fees and fees as percentage</h3>
          <h3>of growth in seconds</h3>
        </div>
      </div>

      <span fxFlex></span>
    </div>
  </div>
  <!-- 
  <div class="bodySection" fxLayout="row" style="display: none">
    <div class="side">
      <span fxFlex></span>

      <div class="text">
        <div>
          <h1>FREE</h1>
          <h1>Consumer-Facing</h1>
          <h1>Direct Marketing Link</h1>
          <div class="separator"></div>
          <h3>Using your custom direct link, consumers can</h3>
          <h3>order a 28 page, full-color custom annuity report.</h3>
        </div>
      </div>

      <span fxFlex></span>
    </div>

    <div class="side">
      <img src="/assets/images/ud_data_report.svg" />
    </div>
  </div> -->

  <div class="bodySection" fxLayout="row">
    <div class="side">
      <span fxFlex></span>

      <div class="text">
        <div>
          <h1>FREE</h1>
          <h1>Withdrawal</h1>
          <h1>Calculators</h1>
          <div class="separator"></div>
          <h3>Four powerful calculations to</h3>
          <h3>increase client income up to 40%</h3>
        </div>
      </div>

      <span fxFlex></span>
    </div>
    <div class="side">
      <img src="/assets/images/ud_calculator.svg" />
    </div>
  </div>

  <div class="bodySection" fxLayout="row">
    <div class="side">
      <img src="/assets/images/ud_online_add.svg" />
    </div>
    <div class="side">
      <span fxFlex></span>

      <div class="text">
        <div>
          <h1>FREE</h1>
          <h1>Advanced Seminar</h1>
          <h1>Tracking Tools</h1>
          <div class="separator"></div>
          <h3>Auto-notifications upon registration and</h3>
          <h3>pre-filled Registration lists and Seminar cards.</h3>
        </div>
      </div>

      <span fxFlex></span>
    </div>


  </div>
</div>

<div class="mobileCardContainer" *ngIf="mobile()">
  <div class="headerSection">
    <div class="imageHeader">
      <div class="main-page-text">
        <h1>Get in Front of</h1>
        <h1>30 Annuity Owners</h1>
        <h1>EVERY Month!</h1>
        <br />
        <p>Come to Scottsdale and learn the #1</p>
        <p>Software/Seminar Process.</p>
        <p>Agents are averaging $500,000 of production</p>
        <p>in the 1st week following our Bootcamp!</p>
      </div>

      <!-- <div>
        <span fxFlex></span>

        <img src="/assets/images/Group 76.svg">

        <span fxFlex></span>
      </div> -->
    </div>

    <mat-card class="mobileRegisterCard">
      <div class="formContainer">
        <div *ngIf="formStep === 1">
          <form [formGroup]="completeRegisterForm">
            <!-- <mat-card-subtitle>Date Attending</mat-card-subtitle> -->
            <mat-form-field appearance="outline">
              <mat-select
                formControlName="event"
                placeholder="Select Event Date"
              >
                <mat-option
                  *ngFor="let value of eventDates; let i = index"
                  [value]="value.actualDate"
                  (click)="setEventId(i)"
                  >{{ value.date }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </form>

          <form [formGroup]="registerForm">
            <mat-card-subtitle>Email</mat-card-subtitle>
            <mat-form-field appearance="outline">
              <input
                matInput
                type="text"
                formControlName="email"
                placeholder="Email"
              />
            </mat-form-field>

            <br />

            <button
              mat-button
              [disabled]="!registerFormValid()"
              (click)="onRegisterButton()"
            >
              Register
            </button>
          </form>
        </div>

        <div *ngIf="formStep == 2">
          <form class="user-details-form" [formGroup]="userDetailsForm">
            <mat-card-subtitle>First Name</mat-card-subtitle>
            <mat-form-field appearance="outline">
              <input
                matInput
                type="text"
                formControlName="firstName"
                placeholder="First Name"
              />
            </mat-form-field>

            <mat-card-subtitle>Last Name</mat-card-subtitle>
            <mat-form-field appearance="outline">
              <input
                matInput
                type="text"
                formControlName="lastName"
                placeholder="Last Name"
              />
            </mat-form-field>

            <mat-card-subtitle>Phone</mat-card-subtitle>
            <mat-form-field appearance="outline">
              <input
                matInput
                type="tel"
                mask="(000) 000-0000"
                formControlName="phone"
                placeholder="Phone"
              />
            </mat-form-field>

            <mat-card-subtitle>Address</mat-card-subtitle>
            <mat-form-field appearance="outline">
              <input
                matInput
                type="text"
                formControlName="street"
                placeholder="Address"
              />
            </mat-form-field>

            <mat-card-subtitle>City</mat-card-subtitle>
            <mat-form-field appearance="outline">
              <input
                matInput
                type="text"
                formControlName="city"
                placeholder="City"
              />
            </mat-form-field>

            <mat-card-subtitle>State</mat-card-subtitle>
            <mat-form-field appearance="outline">
              <input
                matInput
                type="text"
                formControlName="state"
                placeholder="State"
              />
            </mat-form-field>

            <mat-card-subtitle>Zip</mat-card-subtitle>
            <mat-form-field appearance="outline">
              <input
                matInput
                type="text"
                formControlName="zip"
                placeholder="Zip"
              />
            </mat-form-field>

            <mat-card-subtitle>Password</mat-card-subtitle>
            <mat-form-field appearance="outline">
              <input
                matInput
                type="password"
                formControlName="password"
                placeholder="Password"
              />
            </mat-form-field>

            <button
              mat-button
              [disabled]="!userDetailsForm.valid"
              class="registerButton"
              (click)="onNext()"
            >
              Next
            </button>
          </form>
        </div>

        <div *ngIf="formStep === 3">
          <form [formGroup]="completeRegisterForm">
            <!-- <mat-card-subtitle>Are you currently licensed with Annuity Check</mat-card-subtitle>
            <div fxLayout="row">
              <button (click)="changeLicensedValue(true)"
                [ngClass]="completeRegisterForm.get('licensed')?.value === true ? 'activeDecisionButton' : ''"
                class="leftButton decisionButton" mat-button>Yes</button>
              <button (click)="changeLicensedValue(false)"
                [ngClass]="completeRegisterForm.get('licensed')?.value === false ? 'activeDecisionButton' : ''"
                class="rightButton decisionButton" mat-button>No</button>
            </div> -->

            <!-- <mat-card-subtitle>Do you currently host seminars?</mat-card-subtitle>
            <div fxLayout="row">
              <button (click)="changeHostingValue(true)"
                [ngClass]="completeRegisterForm.get('hosting')?.value === true ? 'activeDecisionButton' : ''"
                class="leftButton decisionButton" mat-button>Yes</button>
              <button (click)="changeHostingValue(false)"
                [ngClass]="completeRegisterForm.get('hosting')?.value === false ? 'activeDecisionButton' : ''"
                class="rightButton decisionButton" mat-button>No</button>
            </div> -->

            <!-- Current Imos -->
            <mat-card-subtitle>Current Affiliations</mat-card-subtitle>
            <mat-form-field floatLabel="never" appearance="outline">
              <input
                matInput
                placeholder="List your current IMOs and BD affiliations; separate each by a comma"
                type="text"
                formControlName="currentImos"
                required
              />
            </mat-form-field>

            <mat-card-subtitle
              >Annuity Premium Written: Last 12 Months</mat-card-subtitle
            >
            <mat-form-field appearance="outline">
              <mat-select
                formControlName="premium"
                placeholder="Select Varifiable Premium Range"
              >
                <mat-option
                  *ngFor="let value of annuityPremiumOptions"
                  [value]="value"
                  >{{ value }}</mat-option
                >
              </mat-select>
            </mat-form-field>

            <mat-card-subtitle>Comments</mat-card-subtitle>
            <mat-form-field appearance="outline">
              <textarea
                matInput
                formControlName="comments"
                placeholder="Comments..."
              ></textarea>
            </mat-form-field>

            <button
              mat-button
              [disabled]="!completeRegisterForm.valid"
              class="registerButton"
              (click)="onCompleteRegistration()"
            >
              Complete Registration
            </button>
          </form>
        </div>

        <div *ngIf="formStep === 4">
          <div>
            <span fxFlex></span>

            <div class="dateSquare">
              <p class="small">{{ dayName }}</p>
              <p class="big">{{ dayNumber }}</p>
              <p class="small">{{ monthName }}</p>
            </div>
          </div>

          <h2 class="purpleH2">You are now registered!</h2>

          <p>Someone will be giving you a</p>
          <p>call shortly to confirm your travel plans.</p>

          <div>
            <span fxFlex></span>

            <img src="/assets/images/arrow.png" class="finalImgStep" />

            <span fxFlex></span>
          </div>

          <p>You will receive a confirmation email</p>
          <p>at {{ registerForm.get("email")?.value }}</p>
        </div>
      </div>
    </mat-card>
  </div>

  <div class="bodySection">
    <img src="/assets/images/four-trillion.svg" />

    <div class="main-page-text">
      <h1>4-Trillion</h1>
      <h1>Dollar Market</h1>
      <div class="separator"></div>
      <h3>Over 2 trillion in net Variable Annuity</h3>
      <h3>Assets alone and growing daily.</h3>
    </div>
  </div>

  <div class="bodySection">
    <div class="main-page-text">
      <h1>Qualify for 44 Cent Mail with</h1>
      <h1>no minimums</h1>
      <div class="separator"></div>
      <h3>Industry low for our proven full-color</h3>
      <h3>trifold mailer</h3>
      <div class="separator"></div>
      <p>We've generated 100's of millions of premium from</p>
      <p>current annuity owners at a cost our competitors</p>
      <p>can't match.... PLUS</p>
    </div>

    <img src="/assets/images/ud_message_sent.svg" />
  </div>

  <div class="bodySection">
    <img src="/assets/images/ud_delivery.svg" />

    <div class="main-page-text">
      <h1>Protected</h1>
      <h1>Campaign</h1>
      <h1>Territories</h1>
      <div class="separator"></div>
      <h3>You choose your Zip Codes</h3>
      <h3>(go big or go small, you decide)</h3>
    </div>
  </div>

  <div class="bodySection">
    <div class="main-page-text">
      <h1>Top Carriers</h1>
      <div class="separator"></div>
      <h3>Access to the very best annuity products</h3>
    </div>

    <img src="/assets/images/top-carriers.svg" />
  </div>

  <div class="bodySection">
    <img src="/assets/images/ud_all_data.svg" />

    <div class="main-page-text">
      <h1>Lucrative Marketing</h1>
      <h1>Reimbursement Plan</h1>
      <div class="separator"></div>
      <h3>We directly invest back into your ability to scale</h3>
      <h3>more rapidly</h3>
    </div>
  </div>

  <div class="bodySection">
    <div class="main-page-text">
      <h1>FREE</h1>
      <h1>Proven Powerpoint</h1>
      <h1>Presentation</h1>
      <div class="separator"></div>
      <h3>Stay on the cutting edge at all</h3>
      <h3>times with tools and page by page transcript</h3>
    </div>

    <img src="/assets/images/ud_pie_graph.svg" />
  </div>

  <div class="bodySection">
    <img src="/assets/images/ud_predictive_analytics.svg" />

    <div class="main-page-text">
      <h1>FREE</h1>
      <h1>AnnuityCheck</h1>
      <h1>Snapshot Tool</h1>
      <div class="separator"></div>
      <h3>Calculate fees and fees as percentage</h3>
      <h3>of growth in seconds</h3>
    </div>
  </div>

  <div class="bodySection" style="display: none">
    <div class="main-page-text">
      <h1>FREE</h1>
      <h1>Consumer-Facing</h1>
      <h1>Direct Marketing Link</h1>
      <div class="separator"></div>
      <h3>Using your custom direct link, consumers can</h3>
      <h3>order a 28 page, full-color custom annuity report.</h3>
    </div>

    <img src="/assets/images/ud_data_report.svg" />
  </div>

  <div class="bodySection">
    <img src="/assets/images/ud_calculator.svg" />

    <div class="main-page-text">
      <h1>FREE</h1>
      <h1>Withdrawal</h1>
      <h1>Calculators</h1>
      <div class="separator"></div>
      <h3>Four powerful calculations to</h3>
      <h3>increase client income up to 40%</h3>
    </div>
  </div>

  <div class="bodySection">
    <div class="main-page-text">
      <h1>FREE</h1>
      <h1>Advanced Seminar</h1>
      <h1>Tracking Tools</h1>
      <div class="separator"></div>
      <h3>Auto-notifications upon registration and</h3>
      <h3>pre-filled Registration lists and Seminar cards.</h3>
    </div>

    <img src="/assets/images/ud_online_add.svg" />
  </div>
</div>
