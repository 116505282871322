import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  CreateNewUserInput,
  GraphqlMutationService,
} from '../services/graphql-mutation.service';
import * as moment from 'moment';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
})
export class LandingPageComponent implements OnInit {
  formStep = 1;
  showLastStep = false;
  annuityPremiumOptions = [
    'Less than $5,000,000 Premium',
    'Between $5,000,000 and $10,000,000 Premium',
    'Over $10,000,000 Premium',
  ];
  eventDates: any = [];
  creatingNewUser: Boolean = false;

  dayName = '';
  dayNumber = '';
  monthName = '';

  budget: number = 100000;

  ac_prices: any[] = [
    { value: 0.39, viewValue: '$ 0.39' },
    { value: 0.49, viewValue: '$ 0.49' },
  ];

  lr = {
    ppm: 0.73,
    mailers: 0,
    mail_profit: 0,
    reg_pcnt: 0,
    house_pcnt: 0,
    net_apt_ratio: 0,
    close_ratio: 0,
    prem_per_case: 0,
    total_comp: 0,
    equal_comp_pcnt: 0,
    difference: 0,

    calc_reg_pcnt: 0.75,
    calc_household_pcnt: 60,
    calc_net_apt_ratio: 40,
    calc_closing_ratio: 30,
    calc_premium_per_case: 150000,
    calc_total_comp: 6,
  };

  ac = {
    ppm: 0.39,
    mailers: 0,
    mail_profit: 0,
    reg_pcnt: 0,
    house_pcnt: 0,
    net_apt_ratio: 0,
    close_ratio: 0,
    prem_per_case: 0,
    total_comp: 0,
    equal_comp_pcnt: 0,
    difference: 0,
    calc_reg_pcnt: 0.75,
    calc_household_pcnt: 60,
    calc_net_apt_ratio: 40,
    calc_closing_ratio: 30,
    calc_premium_per_case: 150000,
    calc_total_comp: 6,
  };

  registerForm = new FormGroup({
    email: new FormControl('', [Validators.required]),
  });

  userDetailsForm = new FormGroup({
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
    street: new FormControl(''),
    password: new FormControl(''),
    city: new FormControl('', [Validators.required]),
    state: new FormControl('', [Validators.required]),
    phone: new FormControl('', [Validators.required]),
    zip: new FormControl('', [Validators.required]),
  });

  completeRegisterForm = new FormGroup({
    currentImos: new FormControl('', [Validators.required]),
    premium: new FormControl('', [Validators.required]),
    event: new FormControl('', [Validators.required]),
    eventId: new FormControl(0),
    comments: new FormControl(''),
  });

  constructor(private graphqlMutationService: GraphqlMutationService) {}

  ngOnInit(): void {
    this.fillEventDates();
    this.handleEmailChanges();
    this.handleEventDateChanges();
    this.calc_table(this.lr);
    this.calc_table(this.ac);
    console.log('BUILD DATE: 10/19/2021 at 4:50pm');
  }

  calc_table(tbl: any) {
    tbl.mailers = this.budget / tbl.ppm;
    tbl.reg_pcnt = tbl.mailers * (tbl.calc_reg_pcnt / 100);
    tbl.house_pcnt = tbl.reg_pcnt * (tbl.calc_household_pcnt / 100);
    tbl.net_apt_ratio = tbl.house_pcnt * (tbl.calc_net_apt_ratio / 100);
    tbl.close_ratio = tbl.net_apt_ratio * (tbl.calc_closing_ratio / 100);
    tbl.prem_per_case = tbl.close_ratio * tbl.calc_premium_per_case;
    tbl.total_comp = tbl.prem_per_case * (tbl.calc_total_comp / 100);
  }

  onRegisterButton() {
    const userEmail = this.registerForm.controls['email'].value;
    this.graphqlMutationService
      .getUserByEmail(userEmail)
      .subscribe((data: any) => {
        const user = data.data.users[0];
        if (user) {
          this.creatingNewUser = false;
          const compValue = this.completeRegisterForm.value;

          this.graphqlMutationService
            .insertBCEAttendee(compValue.eventId, user.id)
            .subscribe(() => {
              this.graphqlMutationService.sendEmail(
                user,
                this.formatDate(compValue.event),
                false
              );

              this.formStep += 3;
            });
        } else {
          this.creatingNewUser = true;
          this.formStep++;
        }
      });
  }

  onCompleteRegistration() {
    if (this.creatingNewUser) {
      const regValue = this.registerForm.value;
      const compValue = this.completeRegisterForm.value;
      const userDet = this.userDetailsForm.value;
      const payload: CreateNewUserInput = {
        annuity_premium: compValue.premium,
        city: userDet.city,
        current_imos: compValue.currentImos,
        email: regValue.email,
        first_name: userDet.firstName,
        last_name: userDet.lastName,
        phone: userDet.phone,
        state: userDet.state,
        street: userDet.street,
        zip: userDet.zip,
        business_name: '',
        password: userDet.password,
      };

      this.graphqlMutationService
        .createNewRegistrant(payload)
        .subscribe((data: any) => {
          if (data.data.action_registration.ok) {
            this.graphqlMutationService
              .insertBCEAttendee(
                compValue.eventId,
                data.data.action_registration.id
              )
              .subscribe((data2: any) => {
                this.graphqlMutationService.sendEmail(
                  payload,
                  this.formatDate(compValue.event),
                  true
                );
                this.formStep++;
              });
          }
        });
    }
  }

  onNext() {
    this.formStep++;
  }

  roundTwo(num: number) {
    return (Math.round((num + Number.EPSILON) * 100) / 100).toLocaleString();
  }
  roundSix(num: number) {
    return Math.round((num + Number.EPSILON) * 1000000) / 1000000;
  }
  round(num: number) {
    return Math.round(num).toLocaleString();
  }

  money(num: number) {
    var formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      // These options are needed to round to whole numbers if that's what you want.
      minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    });
    return formatter.format(num);
  }

  onBudgetChange() {
    this.calc_table(this.lr);
    this.calc_table(this.ac);
  }

  onUserDetailsSubmit() {
    const userDetails = this.userDetailsForm.value;
    this.formStep++;
  }
  formatDate(date: any) {
    if (typeof date === 'string') {
      date = moment(date.substring(0, date.length - 14));
    }
    try {
      return date._d.toDateString();
    } catch (e) {
      console.log(e);
      return date;
    }
  }

  fillEventDates() {
    this.graphqlMutationService.getEventDates().subscribe((data: any) => {
      data.data.boot_camp_events.map((item: any) => {
        const m = moment(item.event_date);
        const day = {
          eventId: item.id,
          date:
            m.format('dddd') +
            ', ' +
            m.format('MMMM') +
            ' ' +
            m.date() +
            ', ' +
            m.year() +
            ' - 11am to 5pm',
          actualDate: m,
        };
        if (
          item.boot_camp_attendees_aggregate.aggregate.count < item.capacity &&
          this.eventDates.length < 2
        ) {
          this.eventDates.push(day);
        }
      });
    });
  }

  handleEventDateChanges() {
    this.completeRegisterForm
      .get('event')
      ?.valueChanges.subscribe((data: any) => {
        if (typeof data === 'string') {
          data = moment(data.substring(0, data.length - 14));
        }

        this.dayName = data.format('dddd');
        this.dayNumber = `${data.date()}`;
        this.monthName = data.format('MMMM');
      });
  }

  handleEmailChanges() {
    this.registerForm.get('email')?.valueChanges.subscribe((value: string) => {
      if (value.includes('@') && value.includes('.') && value.length >= 5) {
        this.registerForm.get('email')?.setErrors(null);
      } else {
        this.registerForm.get('email')?.setErrors({ incorrect: true });
      }
    });
  }

  setEventId(i: number) {
    this.completeRegisterForm
      .get('eventId')
      ?.patchValue(this.eventDates[i].eventId);
  }

  registerFormValid() {
    //  && this.registerForm.get('phone')?.value.length === 10
    if (this.registerForm.valid) {
      if (this.completeRegisterForm.get('event')?.value !== '') {
        if (
          this.registerForm.get('email')?.value.includes('@') &&
          this.registerForm.get('email')?.value.includes('.') &&
          this.registerForm.get('email')?.value.length >= 5
        ) {
          this.registerForm.get('email')?.setErrors(null);
          return true;
        }
        this.registerForm.get('email')?.setErrors({ incorrect: true });
        return false;
      }

      return false;
    }
    return false;
  }

  mobile() {
    return window.innerWidth < 1280;
  }
}
